import { DeviceListType } from "./constants"
import { getDeviceActiveStatus } from "./GeneralUtils"
import { dateStringShort } from "./DateUtils"
import GRAY_IMAGE from "../assets/images/icons/icon-gray-glow.png"
import GREEN_IMAGE from "../assets/images/icons/icon-green-glow.png"
import RED_IMAGE from "../assets/images/icons/icon-red-glow.png"
import { getFormattedServiceHours } from "../utils/GeneralUtils"


export const deviceListWithDeviceStatus = (deviceList) => {

    return deviceList.map(device => {
        return { ...device, status: getDeviceActiveStatus(device.attributes) }
    })

}

export const getDeviceOnStatusIcon = (status) =>{

    console.log("status", status)
    if(!status) return GRAY_IMAGE

    if(status === 1) return GREEN_IMAGE
    if(status === 2) return RED_IMAGE

}

export const getFormattedValueByKey = (key, value) =>{

    if(key === "generator_status")
    {
        if(typeof value === "undefined" || value===null) return "UNTRACKED"

        if(value === 0) return "STOPPED"
        if(value === 1) return "RUNNING"
        if(value === 2) return "ERROR"
    }else if(key === 'updated_time')
    {
        if(value === null) return "NA"

        try {
           return dateStringShort(value)
        } catch (error) {
            return "NA"
        }
    }else if(key === 'start_time')
    {
        if(value === null) return "NA"

        try {
           return dateStringShort(value)
        } catch (error) {
            return "NA"
        }
    }else if(key === 'voltage'){
        if(value === null) return "NA"
        return value+"V"

    }else if(key === 'current'){
        if(value === null) return "NA"
        return value+"A"
        
    }else if(key === 'power_factor'){
        if(value === null) return "NA"
        return value
        
    }else if(key === 'vibration_internal'){
        if(value === null) return "NA"
        return vibrationSimplifiedName(value)
        
    }else if(key === 'vibration_external'){
        if(value === null) return "NA"
        return vibrationSimplifiedName(value)
        
    }else if(key === 'frequency'){
        if(value === null) return "NA"
        return value+"Hz"
        
    }else if(key === 'power'){
        if(value === null) return "NA"
        return value+"W"
        
    }else if(key === 'temperature'){
        if(value === null) return "NA"
        return value +" C";
        
    }else if(key === 'bat'){
        if(value === null) return "NA"
        return value;
        
    }else if(key === 'csq'){
        if(value === null) return "NA"
        return value;
        
    }else if(key === 'service_time'){
        if(value === null) return "NA"

        return getFormattedServiceHours(value);
        
    }else if(key === 'state'){
        if(value === null) return "NA"
        return value.toUpperCase();
        
    }
}

const vibrationSimplifiedName =(value) => {

    if(value < 10 && value >= 0)
    {
        return "No vibration"
    }else 
    {
        return "Vibrating"
    }

}

const countDevices = (devices) => {

    if(devices === null) return 0
    return devices.length
}


export const getDevicesByStatusType = (devices, statusType) => {

    if(statusType === DeviceListType.ALL) return devices


    let fiteredDeviceList

    if (statusType === DeviceListType.CONNECTED) {
        fiteredDeviceList = devices.filter(device => {
            return device.pg_on === 1
        })

    } else if (statusType === DeviceListType.DISCONNECTED) {
        fiteredDeviceList = devices.filter(device => {
            return device.pg_on === 0
        })

    } else if (statusType === DeviceListType.UNTRACKED) {
        fiteredDeviceList = devices.filter(device => {

            return typeof device.pg_on === "undefined" || device.pg_on === null
        })

    }

    return fiteredDeviceList

}

export const getCountByDeviceStatus = (devices) => {

    const countObject = {
        deviceCount: 0,
        connected: 0,
        disconnected: 0,
        untracked: 0
      }

    if(devices === null) return countObject

    var deviceCount, connected, disconnected, untracked

    connected = countDevices(devices.filter(device => {
        return device.pg_on === 1
    }))

    disconnected = countDevices(devices.filter(device => {
        return device.pg_on === 0
    }))

    untracked = countDevices(devices.filter(device => {
        return typeof device.pg_on === "undefined" || device.pg_on === null
    }))

    deviceCount = countDevices(devices)

    return {...countObject, deviceCount: deviceCount, connected: connected, disconnected: disconnected, untracked: untracked  }

}

