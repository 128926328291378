import React from "react";
import * as echarts from "echarts";
import {
  visitorsSparcalOption,
  visitsSparcalOption,
} from "../Data/DashbordData";
import { dashboardURLBasedOnUserType } from "../utils/GeneralUtils";
import Button from '@mui/material/Button';
import ButtonGroup from '@mui/material/ButtonGroup';
import List from '@mui/icons-material/List';
import MapIcon from '@mui/icons-material/Map';
import { Activate } from "../utils/constants"
import DeviceActivationSummary from "../screens/Dashbord/DeviceActivationSummary";



class PageHeader extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      toggleMenu: false,
      selectedBtn: 1
    };
    this.onToggleMenu();
  }
  componentDidMount() {
    this.chartPlace();
    this.chartPlace1();
    this.onToggleMenu();
  }
  chartPlace = () => {
    var chartDom = document.getElementById("visitorsSparcalChart");
    var visitorsSparcalChart = echarts.init(chartDom);
    var option;
    option = visitorsSparcalOption;

    option && visitorsSparcalChart.setOption(option);
  };

  chartPlace1 = () => {
    var chartDom = document.getElementById("visitsSparcalChart");
    var visitsSparcalChart = echarts.init(chartDom);
    var option;
    option = visitsSparcalOption;

    option && visitsSparcalChart.setOption(option);
  };

  onToggleMenu = async () => {
    await this.setState({
      toggleMenu: !this.state.toggleMenu,
    });
    const { toggleMenu } = this.state;
    if (!toggleMenu) {
      document.body.classList.remove("layout-fullwidth");
    } else {
      document.body.classList.add("layout-fullwidth");
    }
  };
  render() {
    const { HeaderText, Breadcrumb, handleMap, clickedDeviceStatus , deviceMapButton } = this.props;
    
    const dashboardButton = HeaderText === "Dashboard"
    return (
      <div className="block-header">
        <div className="row">
          <div className="col-xl-3 col-lg-3 col-md-3 col-sm-4">
            <h2>
              <a
                href="#!"
                className="btn btn-xs btn-link btn-toggle-fullwidth"
                onClick={(e) => {
                  e.preventDefault();
                  this.onToggleMenu();
                }}
              >
                <i
                  className={
                    !this.state.toggleMenu
                      ? `fa fa-arrow-left`
                      : "fa fa-bars"
                  }
                ></i>
              </a>{" "}
              {HeaderText}
            </h2>
            <ul className="breadcrumb">
              <li className="breadcrumb-item">
                <a href={dashboardURLBasedOnUserType()}>
                  <i className="icon-home"></i>
                </a>
              </li>
              {Breadcrumb.map((item, index) => {
                return (
                  <li
                    key={item.name + index}
                    className="breadcrumb-item active"
                  >
                    <a href={item.navigate ? item.navigate : null}>
                      {item.name}
                    </a>
                  </li>
                );
              })}
            </ul>

          </div>


          <div className={`col-xl-6 col-lg-6 col-md-6 col-sm-4 text-center`}>
            { dashboardButton && <DeviceActivationSummary clickedDeviceStatus={clickedDeviceStatus} /> }
          </div>


          <div className={`col-lg-7 col-md-4 col-sm-12 text-right display-none`} >
            <div className="inlineblock text-center m-r-15 m-l-15 hidden-sm">
              <div
                id="visitorsSparcalChart"
                className="sparkline text-left"
                style={{ width: "12vh", height: 25 }}
              ></div>
              <span>Visitors</span>
            </div>
            <div className="inlineblock text-center m-r-15 m-l-15 hidden-sm">
              <div
                id="visitsSparcalChart"
                className="sparkline text-left"
                style={{ width: "12vh", height: 25 }}
              ></div>
              <span>Visits</span>
            </div>
          </div>


          {dashboardButton && (<div className={`col-xl-3 col-lg-3 col-md-3 col-sm-4 text-right`} >
            <div className="inlineblock text-center m-r-15 m-l-15">

              <ButtonGroup style={{backgroundColor: '#000000'}} variant="contained" aria-label="outlined primary button group">
                <Button style={this.state.selectedBtn === 1 ? { backgroundColor: '#2986cc' }:{ backgroundColor: '#808080' }} color={this.state.selectedBtn === 1 ? "secondary" : "primary"} onClick={() => {
                  handleMap(Activate.LIST)
                  this.setState({ selectedBtn: Activate.LIST })
                }} startIcon={<List />}>List</Button>
                <Button style={this.state.selectedBtn === 1 ?{ backgroundColor: '#808080' }:{ backgroundColor: '#2986cc' }} color={this.state.selectedBtn === 2 ? "secondary" : "primary"} onClick={() => {

                  handleMap(Activate.MAP)
                  this.setState({ selectedBtn: Activate.MAP })
                }
                } startIcon={<MapIcon />}>Map</Button>
              </ButtonGroup>
            </div>

          </div>)
          }

        </div>
      </div>
    );
  }
}

export default PageHeader;
