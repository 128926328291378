import React from 'react'
import DeviceLogItem from "../../components/DeviceLogItem"

export default function LogItemList(props) {

    const { success, deviceLogs } = props

    return (
        <div>
            <div className={`log-item-container card-design-header`}>

                    <div>PG Run Date</div>
                    <div>BSTID</div>
                    <div>PG Name</div>
                    <div>Site Code</div>
                    <div>Latitude</div>
                    <div>Longitude</div>
                    <div>Location</div>
                    <div>Time</div>
                    <div>Duration</div>
                    <div>Voltage</div>
                    <div>Current</div>
                    <div>Vibration</div>
                    <div>Temperature</div>
                    <div>Status</div>
                    <div>Remarks</div>
                    </div>
            {success && deviceLogs.map((device, index) => {

                 if (index % 2 == 0) {
                    return <DeviceLogItem logObject={device} backgroundTheme={"card-design-item1"} />
                } else {
                    return <DeviceLogItem logObject={device} backgroundTheme={"card-design-item2"} />
                }


            })}
        </div>
    )
}
