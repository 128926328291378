import React, { useEffect, useMemo, useState } from "react";
import PageHeader from "../../components/PageHeader";
import DeviceList1 from "./DeviceList1"
import { useFetch } from "../../customHooks/fetchData"
import { getDevicesURL } from "../../utils/allurls"

export default function DeviceDashboard() {

  const { loading, data } = useFetch(getDevicesURL)
  
  return (
    <div className="dashboard-container">
      <PageHeader
        HeaderText="Device Dashboard"
        Breadcrumb={[{ name: "Devices" }]}
      />
      <div className={`device-main-container`}>
        {loading && <div>Loading...</div> }
        { !loading && <DeviceList1 deviceItems={data.data} /> }
      </div>
    </div>
  )
}