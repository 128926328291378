import React, { useEffect, useState } from "react";
import Button from 'react-bootstrap/Button'
import Spinner from 'react-bootstrap/Spinner'
import PageHeader from "../../components/PageHeader"
import { addNewDeviceURL } from "../../utils/allurls"
import swal from "sweetalert"
import { BASE_HEADERS } from "../../utils/baseValues";
import axios from "axios"
import { useHistory } from "react-router"
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import { useFetch } from "../../customHooks/fetchData";
import { getSitesURL } from "../../utils/allurls"

let modifiedSites

export default function AddSite() {

  const history = useHistory();
  const { data, success } = useFetch(getSitesURL)
  console.log("data", data)

  if(success)
  {
    modifiedSites = (data.data).map((item) => {
      return {...item, label: item.name + ", " + item.address}
    })
  }



  const initDeviceData = {
          name: "",
          vendor_company: "",
          device_id: "",
          responsible_person_name:"",
          responsible_contact_number:""
   
  }
  const [loading, setLoading] = useState(false)
  const [deviceData, setDeviceData] = useState(initDeviceData)


  const handleOnchange = (e) => {
    const fieldName = e.target.name
    const fieldValue = e.target.value
    setDeviceData({
      ...deviceData,
      [fieldName]: fieldValue
    })
  }


  const submitData = (e) => {
    e.preventDefault();

    if (!deviceData.name) return swal("Status", "Device name field is required", "error")
    if (!deviceData.device_id) return swal("Status", "Device id field is required", "error")
    
    setLoading(true)
    submitAddDeviceData()

  }

  const submitAddDeviceData = async () => {

    const body = deviceData


    const headers = BASE_HEADERS()

    try{

      const response = await axios.post(addNewDeviceURL, body, { headers });
      console.log("add data", response.data)
      setLoading(false)
  
      if (response.status === 201) {
  
        swal("Status", "Device created successfully!", "success")
        setDeviceData({
          name: "",
          vendor_company: "",
          device_id: "",
          responsible_person_name:"",
          responsible_contact_number:""
        })
  
      } else {
        swal("Status", response.data.error, "error")
      }

    }catch(error){
      setLoading(false)
      swal("Status", "Device creation failed!", "error")
    }

   
  }

  return (
    <div> {
      (<div className="body">
        <PageHeader
          HeaderText={`Add device`}
          Breadcrumb={[

            { name: "Add", navigate: "" },
          ]}
        />
        <h6></h6>
        <div className="row clearfix">
          <div className="col-lg-6 col-md-12">
            <div className="form-group">
              <span>Device name*</span>
              <input
                className="form-control"
                placeholder=""
                name="name"
                value={deviceData.name}
                type="text"
                onChange={(e) => handleOnchange(e)}
              />
            </div>
            <div className="form-group">
              <span>Device ID*</span>
              <input
                className="form-control"
                placeholder=""
                name="device_id"
                value={deviceData.device_id}
                type="text"
                onChange={(e) => handleOnchange(e)}
              />
            </div>
            <div className="form-group">
              <span>Company</span>
              <input
                className="form-control"
                placeholder=""
                name="vendor_company"
                value={deviceData.vendor_company}
                type="text"
                onChange={(e) => handleOnchange(e)}
              />
            </div>
            <div className="form-group">
              <span>Responsible person name</span>
              <input
                className="form-control"
                placeholder=""
                name="responsible_person_name"
                value={deviceData.responsible_person_name}
                type="text"
                onChange={(e) => handleOnchange(e)}
              />
            </div>
            <div className="form-group">
              <span>Contact number</span>
              <input
                className="form-control"
                placeholder=""
                name="responsible_contact_number"
                value={deviceData.responsible_contact_number}
                type="text"
                onChange={(e) => handleOnchange(e)}
              />
            </div>
            {/* <div className="form-group">
             {success && <Autocomplete
                onChange={(event, value) => {
                  if(value)
                  setDeviceData({
                    ...deviceData,
                    site_id: value.id
                  })
                } } // prints the selected value
                disablePortal
                id="country-select-demo"
                options={modifiedSites}
                renderInput={(params) => <TextField {...params} label="Select sites" />}
              />} 
            </div> */}

            
            <div>

              <Button onClick={(e) => { submitData(e) }} variant="primary">
                {
                  loading && <Spinner
                    as="span"
                    animation="grow"
                    size="sm"
                    role="status"
                    aria-hidden="true"
                  />
                }

                {loading ? "Submitting..." : "Add new device"}
              </Button>

              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;

                   <Button onClick={(e) => { history.push("device-dashboard") }} variant="secondary">
                

                { "Back to device list"}
              </Button>

            </div>

          </div>


        </div>
      </div>)}


    </div>
  )
}
