import React from 'react'
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import { Paper, TableRow, withStyles } from "@material-ui/core";
import { getAttributesValueByKey } from '../../utils/GeneralUtils';
import { getFormattedValueByKey } from '../../utils/DeviceUtitls'
import { ACTIVE, CONNECT_TIME, DISCONNECT_TIME, LAST_SERVICE_TIME, STATUS } from '../../utils/constants';

const StyledTableRow = withStyles((theme) => ({
    root: {
        '&:nth-of-type(odd)': {
            backgroundColor: theme.palette.action.hover,
        },
    },
}))(TableRow);

const StyledTableCell = withStyles((theme) => ({
    head: {
        backgroundColor: "#C0C0C0",
        color: theme.palette.common.black,
    },
    body: {
        fontSize: 14,
    },
}))(TableCell);

export default function DeviceAttributeComponent(props) {

    const { device } = props;

    return (
        <div className="grid-style-container">
           
                <div className="fullWidthAndHeight">
                    <div className="marqueeitem-first" id="status">Status : &nbsp; {getFormattedValueByKey('state', device.log.state)}</div>
                    
                </div>
                <div className="fullWidthAndHeight">
                    <div id="time">Time: &nbsp; {getFormattedValueByKey('updated_time', device.log.time)}</div>
                </div>
                <div className="fullWidthAndHeight">
                    <div id="voltage">Voltage: &nbsp; {getFormattedValueByKey('voltage', device.log.voltage)}</div>
                </div>
                <div className="fullWidthAndHeight">
                    <div id="service_time">Service Time: &nbsp; {getFormattedValueByKey('service_time', device.log.service_time)}</div>
                </div>
                <div className="fullWidthAndHeight">
                    <div id="current">Current: &nbsp; {getFormattedValueByKey('current', device.log.current)}</div>
                </div>
                <div className="fullWidthAndHeight">
                    <div id="start_time">PG start: &nbsp; {getFormattedValueByKey('start_time', device.log.start_time)}</div>
                </div>
                <div className="fullWidthAndHeight">
                    <div id="powerfactor">Power Factor: &nbsp; {getFormattedValueByKey('power_factor', device.log.power_factor)}</div>
                </div>
              
                <div className="fullWidthAndHeight">
                    <div id="frequency">Frequency: &nbsp; {getFormattedValueByKey('frequency', device.log.frequency)}</div>
                </div>
                <div className="fullWidthAndHeight">
                    <div id="power">Power: &nbsp; {getFormattedValueByKey('power', device.log.power)}</div>
                </div>
                <div className="fullWidthAndHeight">
                    <div id="temperature">Temperature: &nbsp; {getFormattedValueByKey('temperature', device.log.temperature)}</div>
                </div>
                <div className="fullWidthAndHeight">
                    <div id="vibration_internal">Vibration Internal: &nbsp; {getFormattedValueByKey('vibration_internal', device.log.vibration_internal)}</div>
                </div>
                <div className="fullWidthAndHeight">
                    <div id="vibration_external">Vibration External: &nbsp; {getFormattedValueByKey('vibration_external', device.log.vibration_external)}</div>
                </div>
                <div className="fullWidthAndHeight">
                    <div id="bat">BAT Voltage: &nbsp; {getFormattedValueByKey('bat', device.log.bat)}</div>
                </div>
                <div className="fullWidthAndHeight">
                    <div id="csq">Signal strength: &nbsp; {getFormattedValueByKey('csq', device.log.csq)}</div>
                </div>

            

        </div>
    )
}
