import React from 'react'
import InstumateStatusCard from "../../components/InstumateStatusCard";
import { useHistory } from 'react-router';
import { dateString } from '../../utils/DateUtils';


export default function DeviceListComponent(props) {


  const history = useHistory();
  const { items } = props

  //console.log("Device list called......",items)

  const redirectToDetails = (device) => {

    console.log(device);
    history.push({ pathname: "/devicedetails", state: device })

  }

  return (
    <div className="list-container">

      {
        items.map((device) => {
          return (<InstumateStatusCard
            key={device.d_id}
            deviceObject={device}
            InstumentToggle={""}
            FirstPeraText={""}
            FirstPeraValue={""}
            SecondPeraText={"Created At"}
            SecondPeraValue={dateString(device.createdTime)}
            openDetails={() => redirectToDetails(device)}
            backgroundTheme={"card-design-black"}
          />)
        }

        )

      }

    </div>
  )
}
