import React, { useState, useEffect } from 'react'
import DeviceServiceHoursRow from "../../components/DeviceServiceHoursRow"
import { getReportsURLByDevice, getGlobalEveryEntryReportsURL } from '../../utils/allurls'
import { useFetch } from '../../customHooks/fetchData'
import TextField from '@mui/material/TextField';
import DateRangePicker from '@mui/lab/DateRangePicker';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import DateTimePicker from '@mui/lab/DateTimePicker';
import Button from '@mui/material/Button';
import axios from 'axios'
import { BASE_HEADERS } from "../../utils/baseValues"
import { dateStringFull } from "../../utils/DateUtils"
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Fade from '@mui/material/Fade';
import { LAST_ONE_HOUR, LAST_TWELVE_HOURS, LAST_TWENTRY_FOUR_HOURS, LAST_SEVEN_DAYS, LAST_THIRTY_DAYS, LAST_180_DAYS, LAST_365_DAYS, LAST_ALL } from '../../utils/constants';
import { downloadCSV } from "../../utils/DownloadOption"
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import { getFilteredOption } from "../../utils/GeneralUtils"

var start_time, end_time

export default function DeviceReports(props) {


    const [checkboxData, setCheckBoxData] = useState({valid_time: true, 
    faulty_time: true, stop_time: false});
    const [startValue, setStartValue] = useState(null);
    const [endValue, setEndValue] = useState(null);
    const [success, setSuccess] = useState(false)
    const [reportData, setReportData] = useState([])
    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = (value) => {
        setAnchorEl(null);
         console.log(value)
        //activityHandler(value)
        downloadCustomReport(value)
    };


    const getDetailsData = async (startTime, endTime) => {
        const headers = BASE_HEADERS()
        const filteredOption = getFilteredOption(checkboxData)
        const response = await axios.get(getGlobalEveryEntryReportsURL(startTime, endTime, filteredOption), { headers });
        if (response.status === 200) {
            setReportData(response.data)
            setSuccess(true)
        }
    }

    const getData = async (startTime, endTime) => {
        const headers = BASE_HEADERS()
        const filteredOption = getFilteredOption(checkboxData)
        const response = await axios.get(getReportsURLByDevice(startTime, endTime, filteredOption), { headers });
        if (response.status === 200) {
            setReportData(response.data)
            setSuccess(true)
        }
    }
    const downloadReport = async () => {

        if (startValue && endValue) {
            const s_time = Math.floor(new Date(startValue).getTime() / 1000)
            const e_time = Math.floor(new Date(endValue).getTime() / 1000)
            const headers = BASE_HEADERS()
            const filteredOption = getFilteredOption(checkboxData)
            const response = await axios.get(getReportsURLByDevice(s_time, e_time, filteredOption), { headers });
            if (response.status === 200) {
                const fileName = 'SummaryReport'
                downloadCSV(response.data.data, fileName)
            }
        }else
        {
            end_time = Math.floor(new Date().getTime() / 1000)
            start_time = end_time - (24 * 60 * 60)
            const headers = BASE_HEADERS()
            const filteredOption = getFilteredOption(checkboxData)
            const response = await axios.get(getReportsURLByDevice(start_time, end_time, filteredOption), { headers });
            if (response.status === 200) {
                const fileName = 'SummaryReport'
                downloadCSV(response.data.data, fileName)
            }
        }

    }

    const downloadGlobalReport = async () => {


                 const fileName = 'CustomReport'

                downloadCSV(reportData.data, fileName)

        // const filteredOption = getFilteredOption(checkboxData)

        // if (startValue && endValue) {
        //     const s_time = Math.floor(new Date(startValue).getTime() / 1000)
        //     const e_time = Math.floor(new Date(endValue).getTime() / 1000)
        //     const headers = BASE_HEADERS()
        //     const response = await axios.get(getGlobalEveryEntryReportsURL(s_time, e_time, filteredOption), { headers });
        //     if (response.status === 200) {
        //         const fileName = 'GlobalReport'

        //         downloadCSV(response.data.data, fileName)
        //     }
        // }else
        // {
        //     end_time = Math.floor(new Date().getTime() / 1000)
        //     start_time = end_time - 24 * 60 * 60
        //     const headers = BASE_HEADERS()
        //     const response = await axios.get(getGlobalEveryEntryReportsURL(start_time, end_time, filteredOption), { headers });
        //     if (response.status === 200) {
        //         const fileName = 'GlobalReport'

        //         downloadCSV(response.data.data, fileName)
        //     }
        // }
    }

    const downloadCustomReport = async (timeInSec) => {

        const filteredOption = getFilteredOption(checkboxData)

        const e_time = Math.floor(new Date().getTime() / 1000)
        const s_time = e_time - timeInSec

            const headers = BASE_HEADERS()
            const response = await axios.get(getGlobalEveryEntryReportsURL(s_time, e_time, filteredOption), { headers });
            if (response.status === 200) {
                const fileName = 'CustomReport'

                downloadCSV(response.data.data, fileName)
            }
    }

    useEffect(() => {

        

        const loadReportsdata = async () => {

            const filteredOption = getFilteredOption(checkboxData)
        if (startValue && endValue) {
            const s_time = Math.floor(new Date(startValue).getTime() / 1000)
            const e_time = Math.floor(new Date(endValue).getTime() / 1000)
            const headers = BASE_HEADERS()
            const response = await axios.get(getGlobalEveryEntryReportsURL(s_time, e_time, filteredOption), { headers });

           // const response = await axios.get(getGlobalEveryEntryReportsURL(s_time, e_time, filteredOption), { headers });
            if (response.status === 200) {
                setReportData(response.data)
                setSuccess(true)
                
            }
        }else
        {
            end_time = Math.floor(new Date().getTime() / 1000)
            start_time = end_time - (24 * 60 * 60)
            const headers = BASE_HEADERS()
            const response = await axios.get(getGlobalEveryEntryReportsURL(start_time, end_time, filteredOption), { headers });
            if (response.status === 200) {
                setReportData(response.data)
                setSuccess(true)
            }
        }

        }

        loadReportsdata()





        // console.log("checked data", checkboxData)
        // end_time = Math.floor(new Date().getTime() / 1000)
        // start_time = end_time - 24 * 60 * 60
        // console.log("[[[[start : end ]]]] ", start_time, end_time)
        // getData(start_time, end_time)

    }, [])

    const handleChange = (event) => {

        console.log("handleChange called",event.target.name, event.target.checked)
        const checkBoxName = event.target.name


        setCheckBoxData((state) => {return {...state, [checkBoxName]: event.target.checked }})

        
    }

    //console.log("render checked data", checkboxData)



    return (
        <div className="grid-style-container3">
            <div className="card">
                <div className="margin10">
                    <div className="time-picker-container">

                        <LocalizationProvider style={{ color: "#ffffff", borderColor: "#ffffff" }} dateAdapter={AdapterDateFns}>
                            <DateTimePicker

                                renderInput={(props) => <TextField {...props} />}
                                label="Start time"
                                value={startValue}
                                onChange={(newValue) => {
                                    console.log("Start time: ", newValue);
                                    console.log("convert start time: ", new Date(newValue).getTime());
                                    setStartValue(newValue);
                                }}
                            />
                        </LocalizationProvider>
                        <div>To</div>
                        <LocalizationProvider style={{ color: "#ffffff", borderColor: "#ffffff" }} dateAdapter={AdapterDateFns}>
                            <DateTimePicker
                                style={{ color: "#ffffff", borderColor: "#ffffff" }}
                                renderInput={(props) => <TextField {...props} />}
                                label="End time"
                                value={endValue}
                                onChange={(newValue) => {
                                    console.log("End time: ", newValue);
                                    console.log("convert end time: ", new Date(newValue).getTime());
                                    setEndValue(newValue);
                                }}
                            />
                        </LocalizationProvider>
                        <div><Button onClick={() => {

                            if (startValue && endValue) {
                                start_time = Math.floor(new Date(startValue).getTime() / 1000)
                                end_time = Math.floor(new Date(endValue).getTime() / 1000)
                                //console.log("submit value start: ", start_time);
                                //console.log("submit value end: ", end_time);
                                getDetailsData(start_time, end_time)
                            }

                        }} style={{ color: "white", borderColor: "gray" }} variant="contained">Search</Button>
                        &nbsp;&nbsp;&nbsp;&nbsp;
                        
                        </div>
                    </div>
                    <div>
                       
                        <FormControlLabel  control={<Checkbox defaultChecked  name="valid_time"  onChange={handleChange}  />}  label="Valid PG run time" />
                        <FormControlLabel  control={<Checkbox defaultChecked   name="faulty_time"  onChange={handleChange}  />}  label="Faulty PG run time" />
                        <FormControlLabel   control={<Checkbox  name="stop_time"  onChange={handleChange}  />}   label="Stopped PG run time" />
                    </div>

                    <div>Report time: {dateStringFull(start_time)} TO {dateStringFull(end_time)}</div>
                 
                </div>
                <div>
                    <div className={`service-hour-item-container card-design-header`}>

                    <div>PG Run Date</div>
                    <div>BSTID</div>
                    <div>PG Name</div>
                    <div>Site Code</div>
                    <div>Latitude</div>
                    <div>Longitude</div>
                    <div>Location</div>
                    <div>Start Time</div>
                    <div>Finish Time</div>
                    <div>Duration</div>
                    <div>Voltage(V)</div>
                    <div>Current(A)</div>
                    <div>Vibration(P/S)</div>
                    <div>Temperature(C)</div>
                    <div>Status</div>
                    <div>Remarks</div>
                   

                    </div>
                    {success && (reportData.data).map((deviceHour, index) => {

                        if (index % 2 == 0) {
                            return <DeviceServiceHoursRow key={deviceHour.device_id} deviceHours={deviceHour} backgroundTheme={"card-design-item1"} />
                        } else {
                            return <DeviceServiceHoursRow key={deviceHour.device_id} deviceHours={deviceHour} backgroundTheme={"card-design-item2"} />
                        }

                    })}

                </div>
            </div>
            <div>

                {/* <Button variant="outline" onClick={downloadReport}>Download Report</Button> */}


            </div>
            <div>

            

            <Button style={{ color: "#ffffff", borderColor: "#ffffff", marginBottom: "10px" }} variant="contained" onClick={downloadGlobalReport}>Download Details Report</Button>&nbsp;&nbsp;&nbsp;
                
                <Button
                id="fade-button"
                style={{ color: "#ffffff", borderColor: "#ffffff", marginBottom: "10px" }} variant="contained"
                aria-controls="fade-menu"
                aria-haspopup="true"
                aria-expanded={open ? 'true' : undefined}
                onClick={handleClick}
            >
                Download Custom report
            </Button> &nbsp;&nbsp;
            <Menu
                id="fade-menu"
                MenuListProps={{
                    'aria-labelledby': 'fade-button',
                }}
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                TransitionComponent={Fade}
            >
                <MenuItem onClick={() => handleClose(LAST_ONE_HOUR)}>Download last 1 hour report</MenuItem>
                <MenuItem onClick={() => handleClose(LAST_TWELVE_HOURS)}>Download last 12 hours report</MenuItem>
                <MenuItem onClick={() => handleClose(LAST_TWENTRY_FOUR_HOURS)}>Download last 24 hours report</MenuItem>
                <MenuItem onClick={() => handleClose(LAST_SEVEN_DAYS)}>Download last 7 days report</MenuItem>
                <MenuItem onClick={() => handleClose(LAST_THIRTY_DAYS)}>Download last 30 days report</MenuItem>
                <MenuItem onClick={() => handleClose(LAST_180_DAYS)}>Download last 180 days report</MenuItem>
                <MenuItem onClick={() => handleClose(LAST_365_DAYS)}>Download last 365 days report</MenuItem>
                <MenuItem onClick={() => handleClose(LAST_ALL)}>Download all report</MenuItem>

            </Menu>

            <Button style={{ backgroundColor:"gray", color: "#ffffff", borderColor: "#ffffff", marginBottom: "10px" }} variant="contained" onClick={downloadReport}>Download Summary Report</Button> &nbsp;&nbsp;


            </div>

        </div>
    )
}
