import exportFromJSON from 'export-from-json'
import { objectToPureArray } from "./GeneralUtils"

export const downloadCSV = (dataObj, fileName) => {

    const data =objectToPureArray(dataObj) 

    const exportType =  exportFromJSON.types.csv
    
    exportFromJSON({ data, fileName, exportType })
}