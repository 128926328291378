import React, { useEffect, useState } from "react";
import PageHeader from "../../components/PageHeader";
import { useLocation } from "react-router-dom"
import { Button, } from '@mui/material';
import { getFormattedData } from "../../utils/GeneralUtils";

import { dateString, dateStringFull } from '../../utils/DateUtils';
import { useHistory } from 'react-router';
import LogItemList from './LogItemList'
import MapItemList from './MapItemList'
import { getDeviceLogDataById } from "../../utils/allurls"
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Fade from '@mui/material/Fade';
import { LAST_ONE_HOUR, LAST_TWELVE_HOURS, LAST_TWENTRY_FOUR_HOURS, LAST_SEVEN_DAYS, LAST_THIRTY_DAYS, LAST_180_DAYS, LAST_365_DAYS, LAST_ALL } from '../../utils/constants';
import { BASE_HEADERS } from "../../utils/baseValues"
import axios from "axios"
import exportFromJSON from 'export-from-json'
import ReactPaginate from 'react-paginate'
import SingleDeviceSummary from "./SingleDeviceSummary"



let deviceLogs = []
var start_time, end_time
var total_page = 0

export default function DeviceDetails() {

  const location = useLocation()
  const [success, setSuccess] = useState(false)
  const device = location.state
  const [deviceLog, setDeviceLog] = useState([])
  const history = useHistory();
  const [showMap, setShowMap] = useState(false)

  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = (value) => {
    setAnchorEl(null);
    console.log(value)
    getCustomReport(value)
  };

  useEffect(() => {

    end_time = Math.floor(new Date().getTime() / 1000)
    start_time = end_time - 24 * 60 * 60
    getData(device.deviceId, start_time, end_time)
  }, [])

  

  const getCustomReport = async (timeInSec) => {

    end_time = Math.floor(new Date().getTime() / 1000)
    start_time = end_time - timeInSec
    getData(device.deviceId, start_time, end_time)
  }

  const getData = async (device_id, startTime, endTime, page = 0, download = 'no') => {
    const headers = BASE_HEADERS()
    const response = await axios.get(getDeviceLogDataById(device_id, startTime, endTime, page, download), { headers });
    if (response.status === 200) {
      total_page = response.data.pagination.total_page
      setDeviceLog(response.data)
      setSuccess(true)
    }
  }

  const downloadLogDataCSV = async () => {

    const headers = BASE_HEADERS()
    const response = await axios.get(getDeviceLogDataById(device.deviceId, start_time, end_time, 0, 'yes'), { headers });
    if (response.status === 200) {
      const data = getFormattedData(response.data.data)
      const fileName = "DeviceDataReport"
      const exportType = exportFromJSON.types.csv
      exportFromJSON({ data, fileName, exportType })
    }
  }



  if (success) {

    deviceLogs = []

    deviceLogs = [...(deviceLog.data).map((logData, index) => {
      return { ...logData, sl: (index + 1) }
    })]
  }


  const getDescription = (description) => {

    if (description) {
      return description
    } else {
      return "No device description available!"
    }
  }



  const handlePageClick = ({ selected }) => {
    console.log("Selected page item: ", selected)
    getData(device.deviceId, start_time, end_time, selected)

  }

  const visiblePagination = () => {
    if (!deviceLog.data && !deviceLog.data.pagination) return false
    if (deviceLog.data.pagination === 0) return false
    total_page = deviceLog.pagination.total_page
    console.log("total_page:", total_page)
    return true
  }

  return (

    <div
      style={{ flex: 1 }}
      onClick={() => {
        document.body.classList.remove("offcanvas-active");
      }}
    >

      <div>
        <div className="container-fluid">
          {device &&
            <PageHeader
              HeaderText={device.name}
              Breadcrumb={[
                { name: "devicelist", navigate: "" },
                { name: device.name, navigate: "" },
              ]}
            />}
          {device && <div className="row clearfix">
            <div className="grid-area-container">
              <div className="grid-area-1">
                <div className="flex-item-1">

                  <SingleDeviceSummary device={device} />
                  
                  </div>
                <div className="flex-item-1">
                  <Button variant="contained" style={{ width:"200px", color: '#fff' }} color={"primary"} onClick={() => {
                    setShowMap((state) => {
                      return !state
                    })
                  }}>{showMap ? "Show list" : "Show on Map"}</Button>
                  <br></br><br></br>
                  <Button
                    id="fade-button"
                    variant="contained"
                    style={{ width:"200px", color: '#fff' }} color={"primary"}
                    aria-controls="fade-menu"
                    aria-haspopup="true"
                    aria-expanded={open ? 'true' : undefined}
                    onClick={handleClick}
                  >
                    Select time duration
                  </Button>
                  <Menu
                    id="fade-menu"
                    MenuListProps={{
                      'aria-labelledby': 'fade-button',
                    }}
                    anchorEl={anchorEl}
                    open={open}
                    onClose={handleClose}
                    TransitionComponent={Fade}
                  >
                    <MenuItem onClick={() => handleClose(LAST_ONE_HOUR)}>Get last 1 hour activity</MenuItem>
                    <MenuItem onClick={() => handleClose(LAST_TWELVE_HOURS)}>Get last 12 hours activity</MenuItem>
                    <MenuItem onClick={() => handleClose(LAST_TWENTRY_FOUR_HOURS)}>Get last 24 hours activity</MenuItem>
                    <MenuItem onClick={() => handleClose(LAST_SEVEN_DAYS)}>Get last 7 days activity</MenuItem>
                    <MenuItem onClick={() => handleClose(LAST_THIRTY_DAYS)}>Get last 30 days activity</MenuItem>
                    <MenuItem onClick={() => handleClose(LAST_180_DAYS)}>Get last 180 days activity</MenuItem>
                    <MenuItem onClick={() => handleClose(LAST_365_DAYS)}>Get last 365 days activity</MenuItem>
                    <MenuItem onClick={() => handleClose(LAST_ALL)}>Get all activity</MenuItem>

                  </Menu>
                  <br></br><br></br>
                  <Button variant="contained" style={{ width:"200px", color: '#fff' }} color={"primary"} onClick={() => {
                    downloadLogDataCSV()
                  }}>Download Report</Button>
                </div>
              </div>
              {!showMap && <div className="grid-area-1">
                <div className="pagination-containter">
                  {success && <div>Search results from {dateStringFull(start_time)} <b>TO</b> {dateStringFull(end_time)}</div>}
                  <div className="align-right-side">{(success && visiblePagination()) && <ReactPaginate
                    previousLabel={'<'}
                    nextLabel={'>'}
                    breakLabel={'...'}
                    breakClassName={'break-me'}
                    pageCount={total_page}
                    marginPagesDisplayed={2}
                    pageRangeDisplayed={5}
                    onPageChange={handlePageClick}
                    containerClassName={"paginationButtons"}
                    activeClassName={"activePaginate"}
                  />}</div>

                </div>
              </div>}

              <div className="grid-area-2">
                {showMap ? <MapItemList success={success} deviceLogs={deviceLogs} /> : <LogItemList success={success} deviceLogs={deviceLogs} />}
              </div>
              <div className="align-right-side">{(success && visiblePagination()) && <ReactPaginate
                    previousLabel={'<'}
                    nextLabel={'>'}
                    breakLabel={'...'}
                    breakClassName={'break-me'}
                    pageCount={total_page}
                    marginPagesDisplayed={2}
                    pageRangeDisplayed={5}
                    onPageChange={handlePageClick}
                    containerClassName={"paginationButtons"}
                    activeClassName={"activePaginate"}
                  />}</div>


            </div>
          </div>}
        </div>
      </div>
    </div>

  )
}
