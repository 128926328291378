import React, { useEffect, useState } from "react";
import Button from 'react-bootstrap/Button'
import Spinner from 'react-bootstrap/Spinner'
import PageHeader from "../../components/PageHeader"
import { addNewSitesURL } from "../../utils/allurls"
import swal from "sweetalert"
import { BASE_HEADERS } from "../../utils/baseValues";
import axios from "axios"
import { useHistory } from "react-router"

export default function AddSite() {

  const history = useHistory();

  const initSiteData = {
    name: "",
    code: "",
    address: "",
    latitude: "",
    longitude: ""
  }
  const [loading, setLoading] = useState(false)
  const [siteData, setSiteData] = useState(initSiteData)


  const handleOnchange = (e) => {
    const fieldName = e.target.name
    const fieldValue = e.target.value
    setSiteData({
      ...siteData,
      [fieldName]: fieldValue
    })
  }


  const submitData = (e) => {
    e.preventDefault();

    if (!siteData.name) return swal("Site add status", "Name field is required", "error")
    setLoading(true)
    submitAddSiteData()

  }

  const submitAddSiteData = async () => {

    const body = siteData


    try {

      const headers = BASE_HEADERS()
      const response = await axios.post(addNewSitesURL, body, { headers });
      console.log("add data", response.data)

      setLoading(false)
      if (response.status === 201) {

        swal("Status", "Site created successfully!", "success")
        setSiteData({
          name: "",
          address: "",
          code: "",
          latitude: "", 
          longitude:"",
        })

      } else {
        swal("Status", response.data.error, "error")
      }

    } catch (error) {
      setLoading(false)
      swal("Status", error.error, "error")

    }


  }

  return (
    <div> {
      (<div className="body">
        <PageHeader
          HeaderText={`Add Site`}
          Breadcrumb={[

            { name: "Add", navigate: "" },
          ]}
        />
        <h6></h6>
        <div className="row clearfix">
          <div className="col-lg-6 col-md-12">
            <div className="form-group">
              <span>Site name*</span>
              <input
                className="form-control"
                placeholder=""
                name="name"
                value={siteData.name}
                type="text"
                onChange={(e) => handleOnchange(e)}
              />
            </div>
            <div className="form-group">
              <span>Code</span>
              <input
                className="form-control"
                placeholder=""
                name="code"
                value={siteData.code}
                type="text"
                onChange={(e) => handleOnchange(e)}
              />
            </div>
            <div className="form-group">
              <span>Address</span>
              <input
                className="form-control"
                placeholder=""
                name="address"
                value={siteData.address}
                type="text"
                onChange={(e) => handleOnchange(e)}
              />
            </div>
            <div className="form-group">
              <span>Latitude</span>
              <input
                className="form-control"
                placeholder=""
                name="latitude"
                value={siteData.latitude}
                type="text"
                onChange={(e) => handleOnchange(e)}
              />
            </div>

            <div className="form-group">
              <span>Longitude</span>
              <input
                className="form-control"
                placeholder=""
                name="longitude"
                value={siteData.longitude}
                type="text"
                onChange={(e) => handleOnchange(e)}
              />
            </div>

            <div>

              <Button onClick={(e) => { submitData(e) }} variant="primary">
                {
                  loading && <Spinner
                    as="span"
                    animation="grow"
                    size="sm"
                    role="status"
                    aria-hidden="true"
                  />
                }

                {loading ? "Submitting..." : "Add new site"}
              </Button>

              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;

              <Button onClick={(e) => { history.push("site-dashboard") }} variant="secondary">


                {"Back to Site list"}
              </Button>

            </div>

          </div>


        </div>
      </div>)}


    </div>
  )
}
