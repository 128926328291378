import React from "react";
import { getDeviceActiveStatus } from "../utils/GeneralUtils";
import { getDeviceOnStatusIcon } from "../utils/DeviceUtitls";
import ICON_GENERATOR from "../assets/images/icons/pg_icon.png"
import ACTIVE_DEVICE_ICON from "../assets/images/icons/icon-green-glow.png"
import INACTIVE_DEVICE_ICON from "../assets/images/icons/icon-gray-glow.png"
import DeviceAttributeComponent from "../screens/Dashbord/DeviceAttributeComponent";
import { THINK_DEVICE_CONSTANT } from "../utils/constants"

export default function InstumateStatusCard(props) {


  const {
    deviceObject, openDetails, backgroundTheme } = props;
  console.log("deviceObject", deviceObject)

  const getDeviceActiveStatus = (status) => {

    return status ? ACTIVE_DEVICE_ICON : INACTIVE_DEVICE_ICON
  }

  return (

    <div className={`${backgroundTheme}`}>
      <div className="center-message-box">{deviceObject.log.message ? deviceObject.log.message: "PG has no activity"}</div>
      <div className={`list-container-item `} onClick={() => { openDetails(deviceObject.name) }}>
      
      <div className="list-left-icon-layout">
        <div className="indicator-image">
          <img alt="Active" width="30px" height="30px" src={getDeviceOnStatusIcon(deviceObject.pg_on)} />
        </div>
        <img width="60px" height="auto" alt="AC" src={ICON_GENERATOR} />
        <h6 style={{ "fontSize": "12px" }}>PG Name:{deviceObject.name}</h6>
        <div className="list-left-icon">
        {/* <span id="op_site_id">Op site ID:{deviceObject.site.id}&nbsp;</span> */}
        <span id="op_bstid">BSTID: {THINK_DEVICE_CONSTANT}{deviceObject.deviceId}&nbsp;</span>
        <span id="op_site_name">Op site:{deviceObject.site.name}&nbsp;</span>
        <span id="op_site_code">Site code:{deviceObject.site.code}&nbsp;</span>
        <span>Op  Coordinate:</span>
        {deviceObject.log.latitude && <span  id="op_site_cordinate">[{deviceObject.log.latitude},{deviceObject.log.longitude}]&nbsp;</span>}
        </div>
      </div>
      <div>
      <DeviceAttributeComponent device={deviceObject} />
      </div>


    </div>
    </div>
    
  )
}
