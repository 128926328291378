import React from "react";
import { getDeviceActiveStatus } from "../utils/GeneralUtils";
import { getDeviceOnStatusIcon } from "../utils/DeviceUtitls";
import ICON_GENERATOR from "../assets/images/icons/pg_icon.png"
import ACTIVE_DEVICE_ICON from "../assets/images/icons/icon-green-glow.png"
import INACTIVE_DEVICE_ICON from "../assets/images/icons/icon-gray-glow.png"
import DeviceAttributeComponent from "../screens/Dashbord/DeviceAttributeComponent";
import { getDateStringOnly, getTimeStringOnly } from "../utils/DateUtils"
import { THINK_DEVICE_CONSTANT } from "../utils/constants"

export default function DeviceLogItem(props) {


  const {logObject, backgroundTheme } = props;
  console.log("logObject", logObject)

  return (

    <div className={`log-item-container ${backgroundTheme}`}>
      {/* <div>PG Run Date</div>
                    <div>BSTID</div>
                    <div>PG Name</div>
                    <div>Site Code</div>
                    <div>Latitude</div>
                    <div>Longitude</div>
                    <div>Location</div>
                    <div>Time</div>
                    <div>Duration</div>
                    <div>Voltage</div>
                    <div>Current</div>
                    <div>Vibration</div>
                    <div>Temperature</div>
                    <div>Status</div>
                    <div>Remarks</div> */}

     
      <div>{logObject.sl==="Sl." ? logObject.time : getDateStringOnly(logObject.log.time)}</div>
      <div>{THINK_DEVICE_CONSTANT}{logObject.deviceId}</div>
      <div>{logObject.name}</div>
      <div>{logObject.site.code}</div>
      <div>{logObject.log.latitude}</div>
      <div>{logObject.log.longitude}</div>
      <div>{logObject.site.address}</div>
      <div>{logObject.sl==="Sl." ? logObject.time : getTimeStringOnly(logObject.log.time)}</div>
      <div>{logObject.log.duration}</div>
      <div>{logObject.log.voltage}</div>
      <div>{logObject.log.current}</div>
      <div>{logObject.log.vibration_internal}</div>
      <div>{logObject.log.temperature}</div>
      <div>{logObject.log.state}</div>
      <div style={{fontSize:"10px"}}>{logObject.log.message}</div>
      
    
     
    </div>
  )
}
