import React from "react";
import { getDeviceActiveStatus } from "../utils/GeneralUtils";
import { getDeviceOnStatusIcon } from "../utils/DeviceUtitls";
import ICON_GENERATOR from "../assets/images/icons/pg_icon.png"
import DeleteIcon from '@mui/icons-material/Delete';
import IconButton from '@mui/material/IconButton';
import swal from 'sweetalert';



export default function DeviceComponent(props) {


  const {
    deviceObject, removeItem } = props;
  console.log("deviceObject", deviceObject)

  const confirmationDelete = (id, name) => {
    swal({
    
      text:`Are you sure you want to delete '${name}' device?`,
      buttons: true,
      dangerMode: true,
    })
      .then((willDelete) => {
        if (willDelete) {

         removeItem(id)
        }
      });
  }

  const getLableItem = (device) => {
  
    return device.name + "<br />"+ "Device ID: "+ device.deviceId+" <br />"+"Site name: "+ device.site.name

  }



  return (
   
    <div className={`list-container-item card-design-black`}>
      
      <div className="indicator-image">
          <img alt="Generator" width="40px" height="40px" src={ICON_GENERATOR} />
        </div>
      <div className="list-left-icon-layout">
        <h6 style={{ "fontSize": "12px" }}>Device ID: {deviceObject.deviceId}</h6>
        <span>{`name: ${deviceObject.name}`}&nbsp;</span>
        {/* <span>{`Site name: ${deviceObject.site.name}`}&nbsp;</span> */}
       
      </div>
      <div>
      <IconButton edge="end" aria-label="delete" onClick={()=>{
         confirmationDelete(deviceObject.deviceId, deviceObject.name)
      }}>
                      <DeleteIcon style={{ color: 'white' }} />
                    </IconButton>
      </div>


    </div>
  )
}
