import React, {useRef, useEffect} from 'react'
import { MapContainer, Marker, TileLayer, Popup, Tooltip, FeatureGroup, useMap} from "react-leaflet";
import { getFormattedValueByKey } from '../../utils/DeviceUtitls'
import { Icon } from "leaflet";
import InstumateStatusCard from '../../components/InstumateStatusCard';
import { dateString } from '../../utils/DateUtils';
import { useHistory } from 'react-router';
import { THINK_DEVICE_CONSTANT } from '../../utils/constants'


const redIcon = new Icon({
    iconUrl: "/images/icons/map_icon_red.svg",
    iconSize: [50, 50]
  });
  const greenIcon = new Icon({
    iconUrl: "/images/icons/map_icon_green.svg",
    iconSize: [50, 50]
  });
  const grayIcon = new Icon({
    iconUrl: "/images/icons/map_icon_gray.svg",
    iconSize: [50, 50]
  });

  const yellowIcon = new Icon({
    iconUrl: "/images/icons/map_icon_yellow.svg",
    iconSize: [50, 50]
  });

export default function MapComponentCustom(props) {

    const history = useHistory();
    const {deviceList, firstTimeLoad} = props
    const groupRef = useRef(null);
    const map = useMap()

    const showPreview = (device) => {
        console.log("map icon clicked......")

         document.getElementById("status").innerHTML = `Status : &nbsp; ${getFormattedValueByKey('generator_status', device.pg_on)}`
         document.getElementById("time").innerHTML = `Time : &nbsp; ${getFormattedValueByKey('updated_time', device.log.time)}`
         document.getElementById("service_time").innerHTML = `Service time : &nbsp; ${getFormattedValueByKey('service_time', device.log.service_time)}`
         document.getElementById("voltage").innerHTML = `Voltage : &nbsp; ${getFormattedValueByKey('voltage', device.log.voltage)}`
         document.getElementById("current").innerHTML = `Current : &nbsp; ${getFormattedValueByKey('current', device.log.current)}`
         document.getElementById("powerfactor").innerHTML = `Power factor : &nbsp; ${getFormattedValueByKey('power_factor', device.log.power_factor)}`
         document.getElementById("frequency").innerHTML = `Frequency : &nbsp; ${getFormattedValueByKey('frequency', device.log.frequency)}`
         document.getElementById("temperature").innerHTML = `Temperature : &nbsp; ${getFormattedValueByKey('temperature', device.log.temperature)}`
         document.getElementById("vibration_internal").innerHTML = `Vibration internal : &nbsp; ${getFormattedValueByKey('vibration_internal', device.log.vibration_internal)}`
         document.getElementById("vibration_external").innerHTML = `Vibration external : &nbsp; ${getFormattedValueByKey('vibration_external', device.log.vibration_external)}`
         document.getElementById("bat").innerHTML = `BAT Voltage : &nbsp; ${getFormattedValueByKey('bat', device.log.bat)}`
         document.getElementById("csq").innerHTML = `Signal strength : &nbsp; ${getFormattedValueByKey('csq', device.log.csq)}`
        // document.getElementById("op_site_id").innerHTML = `Op site ID: &nbsp; ${device.site.id}`
         document.getElementById("op_bstid").innerHTML = `BSTID : &nbsp; ${THINK_DEVICE_CONSTANT}${device.deviceId}`
         document.getElementById("op_site_name").innerHTML = `Op site name : &nbsp; ${device.site.name}`
         document.getElementById("op_site_code").innerHTML = `Op code : &nbsp; ${device.site.code}`
         document.getElementById("op_site_cordinate").innerHTML = `[${device.log.latitude},${device.log.longitude}]`
         document.getElementById("start_time").innerHTML = `PG start : &nbsp; ${getFormattedValueByKey('start_time', device.log.start_time)}`

    }

    const getIconBasedOnStatus = (status) => {
   
        if(typeof status === "undefined" || status === null) return yellowIcon
        if(status === 0) return grayIcon
        if(status === 1) return greenIcon
        if(status === 2) return redIcon
        
    }

    const redirectToDetails = (device) => {

        console.log(device);

        history.push({ pathname: "/devicedetails", state: device })

    }

    useEffect(() => {

        if(firstTimeLoad)
        {
            const group = groupRef.current
            map.fitBounds(group.getBounds())
        }

        
    })

    return (
        <div>
            
                {/* <Circle center={[23.48, 90.895]} pathOptions={fillBlueOptions} radius={20000} /> */}
                
                <FeatureGroup ref={groupRef}>
                { deviceList.map((place, index) => (
                    <Marker
                        key={index}
                        icon={ getIconBasedOnStatus(place.pg_on)}
                        position={[place.log.latitude, place.log.longitude]} // 👈
                        eventHandlers={{ click: () => showPreview(place) }}
                    >
                        {/* show place's title on hover the marker */}
                        <Tooltip>{place.name}</Tooltip>
                        <Popup className="popupClass">
                        <InstumateStatusCard
                            key={place.d_id}
                            deviceObject={place}
                            InstumentToggle={""}
                            FirstPeraText={""}
                            FirstPeraValue={""}
                            SecondPeraText={"Created At"}
                            SecondPeraValue={dateString(place.createdTime)}
                            openDetails={() => redirectToDetails(place)}
                            backgroundTheme={"card-design-white"}
                        />
                    </Popup>
                    </Marker>
                ))}
              </FeatureGroup>
            
        </div>
    )
}
