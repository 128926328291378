import React, {useState} from "react";
import 'bootstrap/dist/css/bootstrap.min.css';
import Logo from "../../assets/images/icons/logo_edotco.svg";
import { userActions } from "../../actions/ForgotPasswordAction";
import swal from "sweetalert";
import Message from "../../utils/Message";
import { Spinner } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux"
import { useHistory } from "react-router";

export default function ForgotPassword() {

const [email, setemail] = useState("");
const dispatch = useDispatch()
const history = useHistory();
const { isLoading, message, success, showMessage, messageDialog } = useSelector(state => state.forgetPasswordReducer);


const submitForgotPassword = (e)=> {
  e.preventDefault();

  dispatch(userActions.recoverPassword(email))
}

const showSuccesMessage = (message) => { 

  swal(message).then(() => {
    history.push('/login')
  })

}
console.log(
  "forgot password render called...."
);
  return (
    
    <div className="theme-cyan">

    { success && showSuccesMessage(message) }

    <div>
      <div className="vertical-align-wrap">
        <div className="vertical-align-middle auth-main">
          <div className="auth-box">
            <div className="top">
              <img src={Logo} alt="Lucid" style={{ height: "70px", width: "auto", margin: "10px" }} />
            </div>
            <div className="card">
              <div className="header">
                <p className="lead">Recover my password</p>
              </div>
              <div className="body">
                <p>Please enter your email address below to receive instructions for resetting password.</p>
                <form className="form-auth-small ng-untouched ng-pristine ng-valid">
                  <div className="form-group">
                    <input name="email" value={email} className="form-control" placeholder="Email" type="text" onChange={(e)=> setemail(e.target.value)} />
                  </div>
                  <div className="form-group">
                    {(showMessage && !isLoading) && <Message data={messageDialog} />}
                  </div>


                  <button className="btn btn-primary btn-lg btn-block" type="submit" onClick={submitForgotPassword}>
                    {isLoading &&
                      <Spinner
                        as="span"
                        animation="grow"
                        size="sm"
                        role="status"
                        aria-hidden="true"
                      />}
                    {isLoading ? "Loading..." : "RESET PASSWORD"}

                  </button>
                  <div className="bottom">
                    <span className="helper-text">Know your password? <a href="login">Login</a></span>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  )
}