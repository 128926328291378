import React from "react";
import { getDeviceActiveStatus } from "../utils/GeneralUtils";
import { getDeviceOnStatusIcon } from "../utils/DeviceUtitls";
import ICON_GENERATOR from "../assets/images/icons/pg_icon.png"
import ACTIVE_DEVICE_ICON from "../assets/images/icons/icon-green-glow.png"
import INACTIVE_DEVICE_ICON from "../assets/images/icons/icon-gray-glow.png"
import DeviceAttributeComponent from "../screens/Dashbord/DeviceAttributeComponent";
import { dateStringShort, dateStringFull, getDateStringOnly, getTimeStringOnly } from "../utils/DateUtils"
import { getFormattedServiceHours, getStatusPG } from "../utils/GeneralUtils"
import { THINK_DEVICE_CONSTANT } from "../utils/constants"

export default function DeviceServiceHoursRow(props) {


  const {deviceHours, backgroundTheme } = props;
  console.log("deviceHours", deviceHours)

  return (

    <div className={`service-hour-item-container ${backgroundTheme}`}>
      <div>{getDateStringOnly(deviceHours.start_time)}</div>
      <div>{THINK_DEVICE_CONSTANT}{deviceHours.device_id}</div>
      <div>{deviceHours.name}</div>
      <div>{deviceHours.site.code}</div>
      <div>{deviceHours.latitude}</div>
      <div>{deviceHours.longitude}</div>
      <div>{deviceHours.site.address}</div>
      <div>{getTimeStringOnly(deviceHours.start_time)}</div>
      <div>{getTimeStringOnly(deviceHours.end_time)}</div>
      <div>{getFormattedServiceHours(deviceHours.total_service_time)}</div>
      <div>{deviceHours.voltage}</div>
      <div>{deviceHours.current}</div>
      <div>{deviceHours.vibration}</div>
      <div>{deviceHours.temperature}</div>
      <div>{getStatusPG(deviceHours.service_pg_on)}</div>
      <div>{deviceHours.message}</div>
     
      
    </div>
  )
}
