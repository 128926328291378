import React, { useState } from 'react'
import { DEVICE_STATS_DATA } from '../../utils/constants'
import Button from '@mui/material/Button';
import ButtonGroup from '@mui/material/ButtonGroup';
import { DeviceListType } from '../../utils/constants'
import { useSelector } from "react-redux"

const styles = {
    "&.MuiButton-root": {
      border: "1px gray solid"
    },
    "&.MuiButton-text": {
      color: "blue"
    },
   
  };

  const stylesSelected = {
    "&.MuiButton-root": {
      border: "1px #c90076 solid"
    },
    "&.MuiButton-text": {
      color: "#c90076"
    },
   
  };

export default function DeviceActivationSummary(props) {

    const { clickedDeviceStatus } = props
    const { countDeviceStatus } = useSelector(state => state.deviceListReducer)
    const { connected, disconnected, untracked, deviceCount } = countDeviceStatus
    const [ styleSelected, setStyleSelected ] = useState(4)
    return (
        <div className="align-items-center">

            <ButtonGroup size="large" aria-label="large button group">
                <Button key={1} onClick={()=>{
                  setStyleSelected(1)
                  clickedDeviceStatus(DeviceListType.CONNECTED)}} sx={styleSelected===1?stylesSelected:styles} key="one">Running({connected})</Button>
                <Button key={2} onClick={()=>{
                  setStyleSelected(2)
                  clickedDeviceStatus(DeviceListType.DISCONNECTED)}}  sx={styleSelected===2?stylesSelected:styles}  key="two">Stopped({disconnected})</Button>
                <Button key={3} onClick={()=>{
                  setStyleSelected(3)
                  clickedDeviceStatus(DeviceListType.UNTRACKED)}}  sx={styleSelected===3?stylesSelected:styles}  key="three">Untracked({untracked})</Button>
                <Button key={4} onClick={()=>{
                  setStyleSelected(4)
                  clickedDeviceStatus(DeviceListType.ALL)}}  sx={styleSelected===4?stylesSelected:styles}  key="three">Total({deviceCount})</Button>
            </ButtonGroup>


        </div>
    )
}
