import React from "react";
import { getDeviceActiveStatus } from "../utils/GeneralUtils";
import { getDeviceOnStatusIcon } from "../utils/DeviceUtitls";
import ICON_GENERATOR from "../assets/images/icons/pg_icon.png"
import ICON_LOCATION from "../assets/images/icons/icon_location_map.png"
import DeleteIcon from '@mui/icons-material/Delete';
import IconButton from '@mui/material/IconButton';
import swal from 'sweetalert';



export default function SiteComponent(props) {


  const {
    siteObject, removeItem } = props;
  console.log("siteObject", siteObject)

  const confirmationDelete = (id, name) => {
    swal({
    
      text:`Are you sure you want to delete '${name}' site?`,
      buttons: true,
      dangerMode: true,
    })
      .then((willDelete) => {
        if (willDelete) {

         removeItem(id)
        }
      });
  }



  return (
   
    <div className={`list-container-item card-design-black`}>
      
      <div className="indicator-image">
          <img alt="Active" width="40px" height="40px" src={ICON_LOCATION} />
        </div>
      <div className="list-left-icon-layout">
        <h5 style={{ "fontSize": "12px" }}>Site ID: {siteObject.id}</h5>
        <h6 style={{ "fontSize": "12px" }}>{siteObject.name}</h6>
        {siteObject.code && <span>Code: {siteObject.code},&nbsp;</span>}
        <span>{siteObject.address}&nbsp;</span>
      </div>
      <div>
      <IconButton edge="end" aria-label="delete" onClick={()=>{
         confirmationDelete(siteObject.id, siteObject.name)
      }}>
                      <DeleteIcon style={{ color: 'white' }} />
                    </IconButton>
      </div>


    </div>
  )
}
