import React, { useEffect, useMemo, useState } from "react";
import PageHeader from "../../components/PageHeader";
import DeviceReports from "./DeviceReports"
import { useFetch } from "../../customHooks/fetchData"
import { getSitesURL } from "../../utils/allurls"

export default function ReportsDashboard() {

  //const { loading, data } = useFetch(getSitesURL)
  
  return (
    <div className="dashboard-container">
      <PageHeader
        HeaderText="Reports Dashboard"
        Breadcrumb={[{ name: "Reports" }]}
      />
      <div className={`device-main-container`}>
        {/* {loading && <div>Loading...</div> } */}
        { <DeviceReports  /> }
      </div>
    </div>
  )
}


