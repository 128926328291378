import swal from "sweetalert"
import { STATUS, TENANT_ADMIN, ACTIVE, LAST_SERVICE_TIME, DeviceStatus, CONNECTED } from "./constants"
import { dateString, formatDateValue } from "./DateUtils"
import { userType } from "./userUtils"
import { getDateStringOnly, getTimeStringOnly } from "./DateUtils"
import { THINK_DEVICE_CONSTANT } from "./constants"



export const getDeviceActiveStatus = (attributes) => {

  if (!attributes) {
    return false
  } else {
    const findAttribute = attributes.find(attribute => {
      return attribute.key === STATUS
    })

    //console.log("findattributes status", findAttribute)

    if (findAttribute) {
      if (findAttribute.value === 1) {
        return true
      } else {
        return false
      }

    } else {
      return false
    }

  }
}

export const getFormattedServiceHours = (hour_time) => {

  let formatHour

  if (hour_time < 60) {
    formatHour = hour_time + " Seconds"
  } else {
    const seconds = hour_time % 60
    const minutes = Math.floor(hour_time / 60)

    if (minutes < 60) {
      formatHour = minutes + " min " + seconds + " sec"
    } else {
      const remainingMinutes = minutes % 60
      const hours = Math.floor(minutes / 60)
      formatHour = hours + " h " + remainingMinutes + " m " + seconds + " s"
    }

  }

  return formatHour

}

export const transportType = [
  {
    id: 1,
    name: "default"
  },
  {
    id: 2,
    name: "MQTT"
  }
]

export const keyToTitle = (key) => {

  if (key === 'GeneratorFrequency') {
    return "Generator Frequency"
  } else if (key === 'BatteryVoltage') {
    return "Battery Voltage"
  } else if (key === 'OutputVoltage') {
    return "Output Voltage"
  } else if (key === 'lastConnectTime') {
    return "Last Connect Time"
  } else if (key === 'lastDisconnectTime') {
    return "Last Disconnect Time"
  } else if (key === 'inactivityAlarmTime') {
    return "Inactivity Alarm Time"
  } else if (key === 'lastActivityTime') {
    return "Last Activity Time"
  }

  return key;
}

export const valueToFormattedValue = (item) => {

  if (item.label1 === 'lastActivityTime' || item.label1 === 'inactivityAlarmTime' || item.label1 === 'lastDisconnectTime' || item.label1 === 'lastConnectTime') {
    return dateString(item.label2)
  } else if (item.label1 === 'Temperature') {
    return `${item.label2} C`
  } else if (item.label1 === 'Humidity') {
    return item.label2 + "%"
  } else if (item.label1 === 'OutputVoltage') {
    return item.label2 + "V"
  } else if (item.label1 === 'GeneratorFrequency') {
    return item.label2 + ""
  } else if (item.label1 === 'Fuel') {
    return item.label2 + "%"
  } else if (item.label1 === 'Humidity') {
    return item.label2 + "%"
  } else if (item.label1 === 'Pressure') {
    return item.label2 + " pascal"
  }

  return item.label2
}

export const getUserEnabledStatus = (user) => {

  const { additionalInfo } = user

  if (additionalInfo.hasOwnProperty('userCredentialsEnabled')) {
    return additionalInfo.userCredentialsEnabled
  } else {
    return true
  }
}

export const dashboardURLBasedOnUserType = () => {

  if (userType() === TENANT_ADMIN) {
    return "dashboard"
  } else {
    return "dashboard"
  }
}

export const showCustomMessage = (message, icon = "warning") => {

  swal(message, {
    icon: icon,
  })

}

export const getAttributesValueByKey = (attributes, key) => {

  if (!attributes) {
    return ""
  } else {
    const findAttribute = attributes.find(attribute => {
      return attribute.key === key
    })

    if (findAttribute) {
      if (findAttribute.key === LAST_SERVICE_TIME) {

        return findAttribute.value

      } else if (findAttribute.key === ACTIVE) {

        if (findAttribute.value === 1) {
          return "True"
        } else {
          return "False"
        }
      } else if (findAttribute.key === STATUS) {

        if (findAttribute.value === 1) {
          return "Connected"
        } else {
          return "Disconnected"
        }
      } else {
        return formatDateValue(findAttribute.value)
      }

    } else {
      return ""
    }

  }

}

export const getUniqueId = () => {
  return 'widget-' + Date.now();
}

export const saveLocalStorageData = (itemName, data) => {
  localStorage.setItem(itemName, data);
}

export const getSavedLocalStorageData = (itemName) => {
  return localStorage.getItem(itemName);
}

export const objectToPureArray = (objectData) => {

  if (!objectData) return [];

  var data = []

  objectData.map(deviceHours => {

    data.push({

      [`PG Run Date`]: getDateStringOnly(deviceHours.start_time),
      [`BSTID`]: THINK_DEVICE_CONSTANT+deviceHours.device_id,
      [`PG Name`]: deviceHours.name,
      [`Site code`]: deviceHours.site.code,
      [`Latitude`]: deviceHours.latitude,
      [`Longitude`]: deviceHours.longitude,
      [`Location(Near by)`]: deviceHours.site.address,
      [`Start Time`]: getTimeStringOnly(deviceHours.start_time),
      [`Finish Time`]: getTimeStringOnly(deviceHours.end_time),
      [`Duration`]: getFormattedServiceHours(deviceHours.total_service_time),
      [`Voltage`]: deviceHours.voltage,
      [`Current`]: deviceHours.current,
      [`Vibration`]: deviceHours.vibration,
      [`Temperature`]: deviceHours.temperature,
      [`Status`]: getStatusPG(deviceHours.service_pg_on),
      [`Remarks`]: deviceHours.message,

      // [`Device ID`]: deviceHours.device_id,
      // [`Start date`]: getDateStringOnly(deviceHours.start_time),
      // [`Start time`]: getTimeStringOnly(deviceHours.start_time),
      // [`End date`]: getDateStringOnly(deviceHours.end_time),
      // [`End time`]: getTimeStringOnly(deviceHours.end_time),
      // [`Service hours`]: getFormattedServiceHours(deviceHours.total_service_time),
      // [`Message`]: deviceHours.message,
      // [`OP count`]: deviceHours.op_count === undefined? "":  deviceHours.op_count,
      // [`Company`]: deviceHours.company,
      // [`Contact person`]: deviceHours.contact_person,
      // [`Contact number`]: deviceHours.contact_number,

    })

    return deviceHours
  })


  return data;
}

export const getFormattedData = (objectData) => {

  if (!objectData) return [];

  var data = []
  objectData.map(deviceHours => {

    data.push({
      [`PG Run Date`]: getDateStringOnly(deviceHours.log.time),
      [`BSTID`]: THINK_DEVICE_CONSTANT+deviceHours.deviceId,
      [`PG Name`]: deviceHours.name,
      [`Site code`]: deviceHours.site.code,
      [`Latitude`]: deviceHours.log.latitude,
      [`Longitude`]: deviceHours.log.longitude,
      [`Location(Near by)`]: deviceHours.site.address,
      [`Time`]: getTimeStringOnly(deviceHours.log.time),
      [`Duration (sec)`]: deviceHours.log.duration,
      [`Voltage`]: deviceHours.log.voltage,
      [`Current`]: deviceHours.log.current,
      [`Vibration`]: deviceHours.log.vibration_internal,
      [`Temperature`]: deviceHours.log.temperature,
      [`Status`]: deviceHours.log.state,
      [`Remarks`]: deviceHours.log.message,

    })

    return deviceHours
  })


  return data;
}

export const getFilteredOption = (stateObject) => {

    const filterArray= []
    if(stateObject.valid_time) filterArray.push(1)
    if(stateObject.faulty_time) filterArray.push(2)
    if(stateObject.stop_time) filterArray.push(0)

    return joinArrayWithComma(filterArray)

}

export const joinArrayWithComma = (arrayObject) => {
  return arrayObject.join(",")
}

export const getStatusPG = (value) => {

  if(value===1) return "Valid PG run"
  else if(value===0) return "PG is stopped"
  else if(value===2) return "Faulty PG run"
  else return ""
}