import React, { useState, useEffect } from 'react'
import { BASE_HEADERS } from "../../utils/baseValues"
import { getURLDeviceDataByIdWithStatus } from "../../utils/allurls"
import axios from "axios"
import { REFRESH_DEVICE_LIST_TIME } from "../../utils/constants";
import InstumateStatusCard from "../../components/InstumateStatusCard";
import { dateString } from '../../utils/DateUtils';
import { useHistory } from 'react-router';

export default function SingleDeviceSummary(props) {

    const [device, setDevice] = useState(props.device)
    const history = useHistory();

    useEffect(()=>{
        refreshDeviceData(device.deviceId)
    }, [])

    useEffect(() => {
        const intervalId = setInterval(() => {

            refreshDeviceData(device.deviceId)
        }, REFRESH_DEVICE_LIST_TIME);

        return () => clearInterval(intervalId);
    }, []);

    const refreshDeviceData = async (id) => {

        console.log("fetch id", id)


        try {

            const headers = BASE_HEADERS()
            const response = await axios.get(getURLDeviceDataByIdWithStatus(id), { headers });
            console.log("fetched data", response.data)
            if (response.data.status === true) {
                if ((response.data.data).length > 0) {
                    setDevice(response.data.data[0])
                }

            }

        } catch (error) {

        }

    }

    const redirectToDetails = (device) => {

        console.log(device);
        history.push({ pathname: "/devicedetails", state: device })
    
      }


    return (
        <div>{device && <InstumateStatusCard
            key={device.d_id}
            deviceObject={device}
            InstumentToggle={""}
            FirstPeraText={""}
            FirstPeraValue={""}
            SecondPeraText={"Created At"}
            SecondPeraValue={dateString(device.createdTime)}
            openDetails={() => redirectToDetails(device)}
            backgroundTheme={"card-design-black"}
        />}</div>
    )
}
