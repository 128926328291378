import React, { useState } from 'react'
import SiteComponent from "../../components/SiteComponent"
import { deleteSitesURL } from "../../utils/allurls"
import { BASE_HEADERS } from "../../utils/baseValues"
import axios from "axios"
import swal from 'sweetalert';


export default function SiteList({ siteItems }) {

    const [siteList, setSiteList] = useState(siteItems)

    const removeItem = async (id) => {

        console.log("delete id",id)

        //const body = {id: 3}
        

        const headers = BASE_HEADERS()
        const response = await axios.delete(deleteSitesURL(id), {headers});
        console.log("delete data", response.data)

        if (response.data.status === true) {
            const filteredSiteList = siteList.filter((item) => {
                return id !== item.id
            })

            setSiteList(filteredSiteList)

        } else {
            swal("Delete status", response.data.error, "error")
        }
    }

    return (
        <div className="grid-4-column">
            {siteList && siteList.map((item) => {
                return <SiteComponent key={item.id} siteObject={item} removeItem={removeItem} />
            })}


        </div>
    )
}
