import React, { useState, useEffect, useRef, useMemo } from "react";
import { MapContainer, TileLayer, Marker, Polyline } from 'react-leaflet';
import Popup from 'react-leaflet-editable-popup';
import { Icon } from "leaflet";
import ManIcon from "../../assets/images/icons/man.png";
import MyComponent from "./MyComponent";
import axios from 'axios';
import { Avatar, ListItem, ListItemAvatar, ListItemText, Typography } from "@mui/material";
import { getFormattedServiceHours } from "../../utils/GeneralUtils"
import { dateStringFull } from "../../utils/DateUtils";
import { THINK_DEVICE_CONSTANT } from "../../utils/constants"

export default function MapItemList(props) {

    const [pinData, setPinData] = useState(null);
    const limeOptions = { color: 'red' }
    const { success, deviceLogs } = props
    
  const pinIcon = new Icon({
    iconUrl: "/images/icons/map_icon_blue.svg",
    iconSize: [50, 50]
  });

  const greenIcon = new Icon({
    iconUrl: "/images/icons/map_icon_green.svg",
    iconSize: [50, 50]
  });

  const grayIcon = new Icon({
    iconUrl: "/images/icons/map_icon_gray.svg",
    iconSize: [50, 50]
  });

  var latestObjectLog
  if(deviceLogs && deviceLogs.length > 0){
    latestObjectLog = deviceLogs[0]
  }

  useEffect(() => {

    if(success)
    {
        if(deviceLogs)
        {
            const filteredList = deviceLogs.filter((item) => {
               return (item.log.latitude !==0 && item.log.latitude !==null) && (item.log.longitude !==0 && item.log.longitude !==null)
            })

            console.log("filteredList", filteredList)

            var tempData = {}

            if(!filteredList) return

            if(filteredList && filteredList.length ===1) {
                tempData = {...tempData, start: [filteredList[0].log.latitude, filteredList[0].log.longitude]}
               
              }else if(filteredList && filteredList.length > 1) {
                const polilineArray = filteredList.map(item => {
                    return [item.log.latitude, item.log.longitude]
                })
                tempData = {...tempData, start: [filteredList[0].log.latitude, filteredList[0].log.longitude],
                    end: [filteredList[ filteredList.length-1].log.latitude, filteredList[filteredList.length-1].log.longitude], 
                    polyline: [polilineArray]}
                  

            }

            console.log("tempData",tempData)

            setPinData(tempData)
 
        }
    }

  },[])

   
   const getLastPinImage = () => {
     if(latestObjectLog && (latestObjectLog.log.state === "running" || latestObjectLog.log.state === "start"))
     {
       return greenIcon
     }

     return grayIcon
   }

      
  const getPopupView = () => {

    return (<Popup open>
      <ListItem alignItems="flex-start">
        <ListItemText
          primary={`Generator`}
          secondary={
            <React.Fragment>
              <Typography
                sx={{ display: 'inline' }}
                component="span"
                variant="body2"
                color="text.primary"
              >
                Start position <br></br>
                {/* time: 04:23 PM <br></br>
                Battery: {pinData.user.battery}%<br></br>
                MGRS: MQ2 GB 123 345 */}
              </Typography>
            </React.Fragment>
          }
        />
      </ListItem>

    </Popup>)

  }

    return (
        <div className="container-map">
      <div className="box" >
        {pinData && <MapContainer center={pinData.end} zoom={12} scrollWheelZoom={true}>


          {pinData.start && <Marker position={pinData.start} icon={pinIcon}>

            {getPopupView()}
      
          </Marker>}

          {pinData.end && <Marker position={pinData.end} icon={getLastPinImage()} 
>
            <Popup open>
              <ListItem alignItems="flex-start">
                <ListItemText
                  primary={latestObjectLog && "BSTID: "+THINK_DEVICE_CONSTANT+ latestObjectLog.deviceId }
                  secondary={
                    <React.Fragment>
                      <Typography
                        sx={{ display: 'inline' }}
                        component="span"
                        variant="body2"
                        color="text.primary"
                      >
                        Last known position
                        <div>
                        Service time: {getFormattedServiceHours(latestObjectLog.log.service_time)} <br></br>
                        Last active time: {dateStringFull(latestObjectLog.log.time)} <br></br>
                        
                        PG name: {latestObjectLog.name} <br></br>
                        Op site name: {latestObjectLog.site.name} <br></br>
                        Op site address: {latestObjectLog.site.address} <br></br>
                        Op code: {latestObjectLog.site.code} <br></br>
                        Op coordinate: [{latestObjectLog.log.latitude}, {latestObjectLog.log.longitude}]  <br></br>
                        
                        </div>
                        
                        {/* time: 04:23 PM <br></br>
                        Battery: {pinData.user.battery}%<br></br>
                        MGRS: MQ2 GB 123 345 */}
                      </Typography>
                    </React.Fragment>
                  }
                />
              </ListItem>

            </Popup>
          </Marker>}

          {pinData.polyline && <Polyline pathOptions={limeOptions} positions={pinData.polyline} />}

          <TileLayer
            url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
            attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
          />
        </MapContainer>}

      </div>
      <div className="topright">
        {/* <MyComponent activityHandler={activityHandler} newTab={newTab} reload={reload} /> */}
      </div>
    </div>
    )
}
