import React, { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import PageHeader from "../../components/PageHeader";
import { Activate, DEVICE_PER_PAGE, DEVICE_STATS_DATA, INIT_PAGE, MAP_SCREEN_ON, REFRESH_DEVICE_LIST_TIME } from "../../utils/constants";
import { getCustomerId } from "../../utils/userUtils";
import DeviceListComponent from "./DeviceListComponent";
import DeviceListMap from "./DeviceListMap";
import { deviceActions } from '../../actions/deviceActions';
import NoDeviceFound from "./NoDeviceFound";
import { DeviceListType } from "../../utils/constants"
import { getDevicesByStatusType, getCountByDeviceStatus } from "../../utils/DeviceUtitls"


var firstTimeLoad = true

export default function Dashbord() {

  const [activateList, setActivateList] = useState(true);
  const [type, setType] = useState(DeviceListType.ALL);

  const dispatch = useDispatch();
  const { items, success, attributeSuccess } = useSelector(state => state.deviceListReducer)


  useEffect(() => {

    firstTimeLoad = true

    dispatch(deviceActions.requestDeviceList())
    if (localStorage.getItem(MAP_SCREEN_ON)) {
      setActivateList(false)
    }

  }, [])

  useEffect(() => {
     const intervalId = setInterval(() => {
      firstTimeLoad = false
      dispatch(deviceActions.requestDeviceList())

    }, REFRESH_DEVICE_LIST_TIME);

    return () => clearInterval(intervalId);
  }, []);


  const handleMapAndList = (value) => {

    if (value === Activate.MAP) {
      setActivateList(false)
      localStorage.setItem(MAP_SCREEN_ON, true)
    } else {
      setActivateList(true)
      localStorage.removeItem(MAP_SCREEN_ON)
    }
  }

  const renderMapOrList = (deviceStatusType) => {
  
    if (items) {
      if (items.length > 0) {
        const filterredDeviceList = getDevicesByStatusType(items, deviceStatusType)
        

        if (activateList) {
          return (
            <DeviceListComponent items={filterredDeviceList}  />
          )
        } else {
          return (<DeviceListMap items={filterredDeviceList} firstTimeLoad={firstTimeLoad} />)
        }

      } else {
        return (<NoDeviceFound />)
      }
    } else {
      return (<NoDeviceFound />)
    }
  }

  const clickedDeviceStatus = (clickedType) =>{
     
    renderMapOrList(clickedType)
    setType(clickedType)
  }

  return (
    <div className="dashboard-container">
      <PageHeader
        HeaderText="Dashboard"
        Breadcrumb={[{ name: "Dashboard" }]}
        handleMap={handleMapAndList}
        clickedDeviceStatus={clickedDeviceStatus}
      />
      <div className={`device-main-container`}>
        <div className="">
          {renderMapOrList(type)}
        </div>
      </div>
    </div>
  )
}


