import React, { useEffect, useMemo, useState } from "react";
import PageHeader from "../../components/PageHeader";
import SiteList from "./SiteList"
import { useFetch } from "../../customHooks/fetchData"
import { getSitesURL } from "../../utils/allurls"

export default function SiteDashboard() {

  const { loading, data } = useFetch(getSitesURL)
  
  return (
    <div className="dashboard-container">
      <PageHeader
        HeaderText="Site Dashboard"
        Breadcrumb={[{ name: "Sites" }]}
      />
      <div className={`device-main-container`}>
        {loading && <div>Loading...</div> }
        { !loading && <SiteList siteItems={data.data} /> }
      </div>
    </div>
  )
}


