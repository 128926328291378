import { BASE_URL, BASE_URL_2 } from "./baseValues"
import { A_DAY_IN_SENCONDS } from "./constants"



//Auth
export const getLoginURL = `${BASE_URL}/api/auth/login`
export const getRefreshTokenURL = `${BASE_URL}/api/auth/token`
export const forgotpasswordURL = `${BASE_URL}/api/noauth/resetPasswordByEmail`

//user
export const getOwnUserDetailsURL = `${BASE_URL}/api/auth/user`
export const getChangePasswordURL = BASE_URL+"/api/auth/changePassword"

export const getDevicesByCustomer = (customerId, pageSize = 10, page = 0, sortType = "DESC" ) => `${BASE_URL}/api/customer/${customerId}/devices?pageSize=${pageSize}&page=${page}&sortProperty=createdTime&sortOrder=${sortType}`
export const getTimeSeriesByDeviceId = (deviceId) => `${BASE_URL}/device/${deviceId}/values/timeseries`
//export const getAlarmsByCustomerId = (customerId, startTime = new Date().getTime() - A_DAY_IN_SENCONDS, endTime = new Date().getTime()) => `${BASE_URL}/api/alarm/customer/${customerId}?pageSize=10&page=0&sortProperty=createdTime&sortOrder=DESC&startTime=${startTime}&endTime=${endTime}&searchStatus=ANY&fetchOriginator=true`
export const getAttributesByDeviceId = (deviceId) => `${BASE_URL}/api/plugins/telemetry/DEVICE/${deviceId}/values/attributes/SERVER_SCOPE`
export const getUserLogoutURL = BASE_URL+"/api/auth/logout"
export const getUserProfileUpdateURL = (isActivationEmailSend) => BASE_URL+"/api/user?sendActivationMail="+isActivationEmailSend
export const updateDeviceURL = () => `${BASE_URL}/api/device`
export const getAlarmsByCustomerId = (customerId, startTime = new Date().getTime() - A_DAY_IN_SENCONDS, endTime = new Date().getTime()) => BASE_URL+"/api/alarm/USER/"+customerId+"?pageSize=10&page=0&sortProperty=createdTime&sortOrder=DESC&startTime="+startTime+"&endTime="+endTime+"&searchStatus=ANY&fetchOriginator=true"
export const acknowledgeOrClearAlarm = (alarmId, type) => `${BASE_URL}/api/alarm/${alarmId}/${type}`
export const getSitesURL = `${BASE_URL_2}/sites`
export const getDevicesURL = `${BASE_URL_2}/devices`
export const deleteSitesURL = (id) => `${BASE_URL_2}/site/delete/${id}`
export const deleteDeviceURL = (id) => `${BASE_URL_2}/device/delete/${id}`
export const addNewSitesURL = `${BASE_URL_2}/site/create`
export const addNewDeviceURL = `${BASE_URL_2}/device/create`
export const getDeviceLogDataById = (deviceId, startTime, endTime, page = 0, download= 'no') => `${BASE_URL_2}/devices-data/${deviceId}?page=${page}&start_time=${startTime}&end_time=${endTime}&download=${download}`
export const getURLDeviceWithStatus = `${BASE_URL_2}/devices-with-status`
export const getURLDeviceDataByIdWithStatus = (id)=> `${BASE_URL_2}/device-with-status-by-id/${id}`
export const getReportsURLByDevice =(startTime, endTime, filter) => `${BASE_URL_2}/service-hours?start_time=${startTime}&end_time=${endTime}&valid_filter=${filter}`
export const getGlobalEveryEntryReportsURL =(startTime, endTime, filter) => `${BASE_URL_2}/every-entry-service-hour?start_time=${startTime}&end_time=${endTime}&valid_filter=${filter}`
