import React, { useState } from 'react'
import DeviceComponent from "../../components/DeviceComponent"
import { deleteDeviceURL } from "../../utils/allurls"
import { BASE_HEADERS } from "../../utils/baseValues"
import axios from "axios"
import swal from 'sweetalert';


export default function DeviceList1({ deviceItems }) {

    const [deviceList, setDeviceList] = useState(deviceItems)

    const removeItem = async (id) => {

        console.log("delete id",id)

        //const body = {id: 3}
        
        const headers = BASE_HEADERS()
        const response = await axios.delete(deleteDeviceURL(id), {headers});
        console.log("delete data", response.data)

        if (response.data.status === true) {
            const filteredDeviceList = deviceList.filter((item) => {
                return id !== item.deviceId
            })

            setDeviceList(filteredDeviceList)

        } else {
            swal("Delete status", response.data.error, "error")
        }
    }

    return (
        <div className="grid-4-column">
            {deviceList && deviceList.map((item) => {
                return <DeviceComponent key={item.id} deviceObject={item} removeItem={removeItem} />
            })}


        </div>
    )
}
