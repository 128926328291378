import React, { useEffect } from 'react'
import { MapContainer, Marker, TileLayer, Popup, Tooltip, FeatureGroup} from "react-leaflet";
import * as L from "leaflet";
import MapComponentCustom from "./MapComponentCustom"


const LeafIcon = L.Icon.extend({
    options: {}
  });





//   const blueIcon = new LeafIcon({
//     iconUrl:
//       "https://chart.apis.google.com/chart?chst=d_map_pin_letter&chld=%E2%80%A2|abcdef&chf=a,s,ee00FFFF"
//   }),
//   greenIcon = new LeafIcon({
//     iconUrl:
//       "https://chart.apis.google.com/chart?chst=d_map_pin_letter&chld=%E2%80%A2|2ecc71&chf=a,s,ee00FFFF"
//   });

var onetimeLoad = true


export default function DeviceListMap(props) {

    
    const { items, firstTimeLoad } = props
    const bounds = []
    var defaultPosition = [23.22, 90.22];

    useEffect(() => {

    }, [])
    
    const updatedDeviceList = items.filter((item) => {

        if((item.log.latitude !==0 && item.log.latitude !==null) && (item.log.longitude !==0 && item.log.longitude !==null))
        {
            bounds.push([item.log.latitude,item.log.longitude])
            defaultPosition = [item.log.latitude,item.log.longitude]
            return true
        }
        return false

    })



    return (
        <div className="map-container">
            {/* <button onClick={}>Zoom test</button>
            <input id="testin" type="text" ref={testInput} /> */}
            {bounds.length > 0 ? (<MapContainer
               
               center={defaultPosition}
               bounds={bounds} 
               zoom={10}>
               <MapComponentCustom deviceList={updatedDeviceList} firstTimeLoad={firstTimeLoad} />    
               {/* <Circle center={[23.48, 90.895]} pathOptions={fillBlueOptions} radius={20000} /> */}
               
               {/* <Polyline positions={poligonProps()} />  */}
               <TileLayer
                   attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
                   url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
               />
           </MapContainer>) : (<MapContainer
               
               center={defaultPosition}
               zoom={10}>
               
               <TileLayer
                   attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
                   url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
               />
           </MapContainer>)}
            
        </div>
    )
}
